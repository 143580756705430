.Container {
  padding:30px 0 36px 0px;
  margin:0 auto;
  max-width:1050px;
}

.SkinnyContainer {
  padding:30px 20px;
  margin:0 auto;
  max-width:780px;
}

.centered {
  text-align:center;
}

*:focus {
  outline: none;
  cursor: pointer;
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .Container {
    padding:30px 0;
    margin:0 auto;
  }

  .SkinnyContainer {
    padding:30px 20px 36px 20px;
    margin:0 auto;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .Container {
    padding:30px 0px 36px 0px;
    margin:0 auto;
  }

  .SkinnyContainer {
    padding:30px 20px;
    margin:0 auto;
  }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .Container {
    padding:30px 0;
    margin:0 auto;
  }

  .SkinnyContainer {
    padding:30px 0;
    margin:0 auto;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .Container {
    padding:30px 0;
    margin:0 auto;
    max-width:1050px;
  }

  .SkinnyContainer {
    padding:30px 0;
    margin:0 auto;
    max-width:780px;
  }
}
