@font-face {
    font-family: 'Andoina-Regular';
    src: url('./fonts/Anodina-Regular.otf') format("opentype");
}
@font-face {
    font-family: 'Andoina-Light';
    src: url('./fonts/Anodina-Light.otf') format("opentype");
}
@font-face {
    font-family: 'Andoina-Bold';
    src: url('./fonts/Anodina-Bold.otf') format("opentype");
}
@font-face {
    font-family: 'Moon';
    src: url('./fonts/Moon2.0-Bold.otf') format("opentype");
}

h1, h2, h3, h4, h5, h6, p {
  margin-top:0px;
}

h1 {
  font-size: 26px;
  font-family: 'Andoina-Bold';
  color:#25375E;
}
h2 {
  font-size:24px;
  font-family: 'Andoina-Bold';
  color:#25375E;
}
h3 {
  font-size:22px;
  line-height:24px;
  font-family: 'Andoina-Bold';
  color:#25375E;
}
h4 {
  font-size:20px;
  font-family: 'Andoina-Bold';
  color:#25375E;
}
h5 {
  font-size:18px;
  font-family: 'Andoina-Bold';
  color:#25375E;
}
h6 {
  font-size:16px;
  font-family: 'Andoina-Bold';
  color:#25375E;
}

p {
  font-size:16px;
  font-family: 'Andoina-Regular';
  color:#696E77;
  line-height:18px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 22px;
    line-height:24px;

  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 20px;
  }
  p {
    font-size: 18px;
    line-height: 20px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 24px;
    line-height:26px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 18px;
  }
  p {
    font-size: 16px;
    line-height: 18px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 26px;
    line-height:28px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 22px;
  }
  h6 {
    font-size: 20px;
  }
  p {
    font-size: 18px;
    line-height: 20px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 28px;
    line-height:30px;
  }
  h4 {
    font-size: 26px;
  }
  h5 {
    font-size: 24px;
  }
  h6 {
    font-size: 22px;
  }
  p {
    font-size: 20px;
    line-height: 24px;
  }
}
