.SponsorItem {
  align-items: center;
  display:grid;

  a {
    img {
      width:100px;
      display:block;
    }
  }

}
